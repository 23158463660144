import React, { useEffect, useState, useRef } from "react";
import { Box, Center } from "@chakra-ui/react";
import TileBox from "./TileBox";
import { observer } from "@legendapp/state/react";
import { findMatchingApplications, getBoundingBoxDimensions } from "../../utils/commonUtils";
import {
  DraggedImageData,
  Placement,
} from "../../state/types";
import { moodboardConfigState } from "../../state/store";

interface TemplateProps {
  isDropdownOpenIndex: number | null;
  imageDataArray: Placement[];
  handleToggleDropdown: (e: React.MouseEvent, index: number | null) => void;
  handleUpdateCheckedOptions: (
    index: number,
    selectedApplication?: string
  ) => void;
  handleDuplicate: (index: number, callback:(value:boolean)=>void) => void;
  handleRemove: (index: number) => void;
  isEmptyTemplate: boolean;
  draggedImageData: DraggedImageData | null;
  setImageData: (data: Placement[] | ((prev: Placement[]) => Placement[]) ) => void;
  dragPosition: { x: number; y: number };
  templateRef: React.RefObject<HTMLDivElement>;
  setIsDropdownOpenIndex: (index: number | null) => void;
}

const Template = observer(
  React.forwardRef<HTMLDivElement, TemplateProps>(
    (
      {
        isDropdownOpenIndex,
        imageDataArray,
        handleToggleDropdown,
        handleUpdateCheckedOptions,
        handleDuplicate,
        handleRemove,
        isEmptyTemplate,
        draggedImageData,
        setImageData,
        dragPosition,
        templateRef,
        setIsDropdownOpenIndex,
      },
      ref
    ) => {
      const LONG_PRESS_THRESHOLD = 500;

      const moodboardConfig = moodboardConfigState.get();

      const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(
        null
      );
      const [draggedImage, setDraggedImage] = useState<DraggedImageData | any>(
        null
      );
      const [isLongPress, setIsLongPress] = useState(false);
      const longPressTimeout = useRef<NodeJS.Timeout | null>(null);

      const { width, height } = getBoundingBoxDimensions(imageDataArray);

      const dynamicStyle = {
        itemBox: (image: Placement, index: number) => ({
          top: `${image?.y}px`,
          left:
            width > 345 ? `${(image?.x || 0)- (width - 345) / 2}px` : `${image?.x}px`,
          width: image?.width,
          height: image?.height,
          zIndex:
            isDropdownOpenIndex === index ? (image?.layer || 0) + 50 : image?.layer,
          _hover: {
            zIndex:
              isDropdownOpenIndex === index
                ? (image?.layer || 0) + 24
                : (image?.layer || 0) + 15,
          },
          opacity:
            isLongPress
              ? draggedItemIndex === index
                ? 1
                : 0.7
              : 1,
        }),
      };

      const handleLongPressStart = (index: number) => {
        longPressTimeout.current = setTimeout(() => {
          setIsLongPress(true);
          setIsDropdownOpenIndex(null)
        }, LONG_PRESS_THRESHOLD);
      };

      const handleLongPressEnd = () => {
        if (longPressTimeout.current) {
          clearTimeout(longPressTimeout.current);
          longPressTimeout.current = null;
        }
        setIsLongPress(false);
        setDraggedItemIndex(null);
      };

      useEffect(() => {
        setDraggedImage(draggedImageData);
      }, [draggedImageData]);

      const handleDragStart = (index: number) => {
        if (isLongPress) {
          setDraggedItemIndex(index);
        
          const draggedItem = imageDataArray[index];
          const optionsData = {
            value: draggedItem?.product?.applications,
            name: "",
            type: "",
          };

          if (optionsData) {
            setDraggedImage({ data: { optionsData: optionsData } });
          }
        }
      };

      const handleDrop = (index: number) => {
        if (isEmptyTemplate && draggedItemIndex !== null) {
          setImageData((data :Placement[]) => {
            const draggedItem = data[draggedItemIndex];
            const dataArray = index === data?.length ? [...data, draggedItem] : [...data];
            
            const newData =  dataArray.map((item, i) => {
              if (i === draggedItemIndex) {
                return {
                  ...item,
                  product: {skuId:""},
                };
              } else if (i === index) {
                return {
                  ...item,
                  product: draggedItem.product,
                };
              } else {
                return item;
              }
            });

            return newData
          });
        }

        if (isEmptyTemplate || draggedItemIndex === null) return;
        const draggedItem = imageDataArray[draggedItemIndex];

        const isOptionsAvailable = findMatchingApplications(draggedItem?.product?.applications, imageDataArray[index]?.allowApplications)
        
          setImageData((data :Placement[]) => {
            const newData =  data.map((item, i) => {
              if (isOptionsAvailable) {
                if (i === draggedItemIndex) {
                  return {
                    ...item,
                    product: {skuId:''},
                  };
                } else if (i === index) {
                  return {
                    ...item,
                    product: draggedItem.product,
                  };
                } else {
                  return item;
                }
              } else {
                return item;
              }
            });

            return newData
          });
        handleLongPressEnd();
      };

      const centerRef = ref as React.RefObject<HTMLDivElement>;

      const handleCloseToolbar = (
        e: React.MouseEvent,
        ref: React.RefObject<HTMLDivElement>
      ) => {
        if (ref.current && e.target === ref.current) {
          handleToggleDropdown(e, null);
        }
      };

      const imageArray = isEmptyTemplate
        ? imageDataArray?.sort((a: Placement, b: Placement) => 
            (a?.id?.toString() ?? "").localeCompare(b?.id?.toString() ?? "")
          )
        : [...imageDataArray];

      return (
        <Center
          ref={centerRef}
          sx={
            moodboardConfig?.classes?.templateContainer
              ? {}
              : styles.templateContainer
          }
          onDrop={handleLongPressEnd}
          onClick={(e) => handleCloseToolbar(e, centerRef)}
          className={moodboardConfig?.classes?.templateContainer}
        >
          <Box
            ref={templateRef}
            sx={
              moodboardConfig?.classes?.templateBox
                ? {
                    height: `${height}px`,
                    width: `${width}px`,
                    transform: `translate(${dragPosition.x}px, ${dragPosition.y}px)`,
                  }
                : {
                    ...styles.templateBox,
                    height: `${height}px`,
                    width: `${width}px`,
                    transform: `translate(${dragPosition.x}px, ${dragPosition.y}px)`,
                  }
            }
            onClick={(e) => handleCloseToolbar(e, templateRef)}
            className={moodboardConfig?.classes?.templateBox || ""}
          >
            {imageArray?.map((image: Placement, index) => {
              return (
                <Box
                  key={index}
                  sx={{ ...styles.box, ...dynamicStyle.itemBox(image, index) }}
                  data-index={index}
                  draggable={
                    isLongPress && !!image?.product?.skuId
                  }
                  onMouseDown={() => handleLongPressStart(index)}
                  onMouseUp={handleLongPressEnd}
                  onDragStart={() => handleDragStart(index)}
                  onDragOver={(e) => {
                     e.preventDefault();
                     e.currentTarget.style.zIndex = isDropdownOpenIndex === index
                     ? (image?.layer || 0) + 24 + ""
                     : (image?.layer || 0) + 15 + ""
                  }}
                  onDragLeave={(e) => {
                    e.preventDefault();
                    e.currentTarget.style.zIndex = ""
                  }}
                  onDrop={(e) => {
                    e.currentTarget.style.zIndex = ""
                    draggedItemIndex !== null &&
                    isLongPress &&
                    handleDrop(index)
                  }}
                  className={moodboardConfig?.classes?.tileContainer || ""}
                >
                  <TileBox
                    image={image}
                    style={{ width: image?.width || 0, height: image?.height || 0}}
                    handleToggleDropdown={handleToggleDropdown}
                    isDropdownOpenIndex={isDropdownOpenIndex}
                    handleUpdateCheckedOptions={handleUpdateCheckedOptions}
                    isLoading={image?.product?.loading}
                    handleDuplicate={handleDuplicate}
                    handleRemove={handleRemove}
                    index={index}
                    isEmptyTemplate={isEmptyTemplate}
                    draggedImageData={draggedImage}
                    draggable={
                      isLongPress && !!image?.product?.skuId
                    }
                    cursor={
                      isLongPress && !!image?.product?.skuId
                        ? "grab"
                        : "pointer"
                    }
                    templateWidth={width}
                    templateHeight={height}
                    trayHeight={centerRef.current?.offsetHeight || 0}
                  />
                </Box>
              );
            })}
          </Box>
        </Center>
      );
    }
  )
);

export default React.memo(Template);

const styles = {
  templateContainer: {
    width: "100%",
    height: "100%",
  },
  templateBox: {
    position: "relative",
    transformOrigin: "left top",
  },
  box: {
    position: "absolute",
    transition: "zIndex 0.1s ease-in-out 0.1s",
  },
};
