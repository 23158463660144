// src/state/store.ts

import { observable } from "@legendapp/state";
import {
  Config,
  DraggedImageData,
  Project,
  Placement,
  Template,
  ApiConfig,
  TemplateConfig,
} from "./types";
import designConfig from '../configuration/designConfig.json'
import templateLocalConfig from "../configuration/templateLocalConfig.json";
import templateConfig from "../configuration/templateConfig.json";

export const moodboardConfigState = observable<Config>(designConfig);
export const apiConfigState = observable<ApiConfig | null>(null);

export const imageDataState = observable<Array<Placement>>([]);
export const draggedImageDataState = observable<DraggedImageData | null>(null);
export const isDropdownOpenIndexState = observable<number | null>(null);

export const projectsState = observable<Project[]>([]);
export const activeProjectIndexState = observable<any>(null);
export const projectNameState = observable<string>("");

export const templateState = observable<Template>({key:"empty_template"});
export const templateCoordinateState = observable<Placement[][]>(
  []
);
export const isTemplateLoadingState = observable<boolean>(true);
export const templateConfigState = observable<TemplateConfig>({...templateLocalConfig, ...templateConfig});