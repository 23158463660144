import React, { useState } from "react";
import { Box, Icon, Img, Skeleton, Text } from "@chakra-ui/react";
import ToolbarMenu from "./ToolbarMenu";
import { AddIcon } from "@chakra-ui/icons";
import { DraggedImageData, Placement } from "../../state/types";
import { findMatchingApplications, findMatchingKeys, getTagPosition, getToolbarPosition } from "../../utils/commonUtils";
import { moodboardConfigState } from "../../state/store";
import WarnTooltip from "./WarnTooltip";

interface TileBoxProps {
  image: Placement;
  index: number;
  handleToggleDropdown: (e: React.MouseEvent, index: number | null) => void;
  isDropdownOpenIndex: number | null;
  style: {
    width: number;
    height: number;
  };
  templateWidth: number;
  templateHeight: number;
  handleUpdateCheckedOptions: (
    index: number,
    selectedApplication?: string
  ) => void;
  isLoading?: boolean;
  handleDuplicate: (index: number, callback:(value:boolean)=>void) => void;
  handleRemove: (index: number) => void;
  id?: string;
  isEmptyTemplate: boolean;
  draggedImageData: DraggedImageData | null;
  draggable: boolean;
  cursor: string;
  trayHeight: number;
}

const tooltipTitles = {
  applicationWarning:"Cannot be applied on this application",
  duplicationWarning:"Moodboard includes maximum tiles. Drop the product on an existing tile."
}

const TileBox: React.FC<TileBoxProps> = ({
  image,
  index,
  isDropdownOpenIndex,
  style,
  templateWidth,
  templateHeight,
  isLoading,
  handleToggleDropdown,
  handleDuplicate,
  handleRemove,
  handleUpdateCheckedOptions,
  isEmptyTemplate,
  draggedImageData,
  draggable,
  cursor,
  trayHeight,
}) => {
  const toolbarHeight = image?.product?.skuId
    ? isEmptyTemplate
      ? 80
      : 36
    : 46;
  const toolbarWidth = 270;

  const menubarPosition = getToolbarPosition(
    image,
    templateHeight,
    templateWidth,
    trayHeight,
    toolbarHeight,
    toolbarWidth
  );

  const [tooltip, setTooltip] = useState({
    visible:false,
    title:""
  });
  const moodboardConfig = moodboardConfigState.get();

  if (isLoading) {
    return (
      <Skeleton
        sx={{ h: "100%", w: "100%", borderRadius: "md", boxShadow: "md" }}
      />
    );
  }

  const isOptionAvailable = findMatchingApplications(draggedImageData?.data?.optionsData?.value, image?.allowApplications)

  const handleDragEnter = (): void => {
    if (
      draggedImageData &&
      !isOptionAvailable &&
      !isEmptyTemplate
    ) {
      setTooltip({
        title: tooltipTitles.applicationWarning,
        visible: true,
      });
    }
  };

  const handleDragLeave = (): void => {
    setTooltip((prev) => ({
      ...prev,
      visible: false,
    }));
  };

  const handleShowTooltip = (value: boolean): void => {
    if (value) {
      setTooltip({
        title: tooltipTitles.duplicationWarning,
        visible: true,
      });
  
      // Hide the tooltip after 5 seconds
      setTimeout(() => {
        setTooltip((prev) => ({
          ...prev,
          visible: false,
        }));
      }, 3000);
    }
  };

  const productApplications = image?.product?.applications || [];
  const displayApplications = image?.displayApplications
  const applications = findMatchingKeys(displayApplications as { [x: string]: string[]; }, productApplications)

  return (
    <Box
      sx={
        image?.product?.src
          ? moodboardConfig?.classes?.tileBox
            ? { cursor: cursor }
            : { ...styles.tileBox, cursor: cursor }
          : moodboardConfig?.classes?.emptyTileBox
          ? { cursor: cursor }
          : { ...styles.emptyTileBox, cursor: cursor }
      }
      data-index={index}
      onDragOver={handleDragEnter}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDropCapture={handleDragLeave}
      className={
        image?.product?.src
          ? moodboardConfig?.classes?.tileBox || ""
          : moodboardConfig?.classes?.emptyTileBox || ""
      }
    >
      <WarnTooltip
        isVisible={tooltip?.visible}
        title={tooltip?.title}
        menubarPosition={menubarPosition}
      />
      <Box
        sx={
          moodboardConfig?.classes?.tileBoxImageWrapper
            ? {
                height: style.height,
                border:
                  isDropdownOpenIndex === index
                    ? "2px solid var(--Gray-900, #27292C) !important"
                    : "none",
              }
            : {
                ...styles.tileBoxImageWrapper,
                height: style.height,
                border:
                  isDropdownOpenIndex === index
                    ? "2px solid var(--Gray-900, #27292C) !important"
                    : "none",
              }
        }
        onClick={(e) => handleToggleDropdown(e, index)}
        onDragOver={(e) => {
          e.preventDefault();
          e.currentTarget.style.border =
            draggedImageData && !isOptionAvailable && !isEmptyTemplate
              ? "none"
              : "2px solid black";
        }}
        onDragLeave={(e) => {
          e.currentTarget.style.border = "none";
        }}
        onDropCapture={(e) => {
          e.currentTarget.style.border = "none";
        }}
        data-index={index}
        className={moodboardConfig?.classes?.tileBoxImageWrapper}
      >
        {!image?.product?.src ? (
          <Icon
            as={AddIcon}
            sx={moodboardConfig?.classes?.tileIcon ? {} : styles.tileIcon}
            data-index={index}
            className={moodboardConfig?.classes?.tileIcon || ""}
          />
        ) : (
          <Img
            sx={
              moodboardConfig?.classes?.tileBoxImage
                ? { height: style?.height, width: style?.width }
                : {
                    ...styles.tileBoxImage,
                    height: style?.height,
                    width: style?.width,
                  }
            }
            src={image.product?.src}
            alt=""
            data-index={index}
            draggable={draggable}
            className={moodboardConfig?.classes?.tileBoxImage || ""}
          />
        )}

        {image.selectedApplication?.toString() && (
          <Text
            sx={
              moodboardConfig?.classes?.tileBoxTag
                ? {
                    ...getTagPosition(image?.tagPosition),
                    bg:
                      isDropdownOpenIndex === index
                        ? "var(--gray-900, #27292C)"
                        : "var(--gray-600, #797F84)",
                  }
                : {
                    ...styles.tileBoxTag,
                    ...getTagPosition(image?.tagPosition),
                    bg:
                      isDropdownOpenIndex === index
                        ? "var(--gray-900, #27292C)"
                        : "var(--gray-600, #797F84)",
                  }
            }
            data-index={index}
            className={moodboardConfig?.classes?.tileBoxTag || ""}
          >
            {image.selectedApplication}
          </Text>
        )}
      </Box>

      {isDropdownOpenIndex === index && (
        <Box data-index={index}>
          <ToolbarMenu
            applications={applications}
            menubarPosition={menubarPosition}
            handleUpdateCheckedOptions={handleUpdateCheckedOptions}
            handleDuplicate={() => {
              handleDuplicate(index, handleShowTooltip);
            }}
            handleRemove={handleRemove}
            index={index}
            selectedApplication={image?.selectedApplication}
            title={image?.product?.title}
            isEmptyTemplate={isEmptyTemplate}
            isEmptyTile={!image.product?.src}
            toolbarWidth={toolbarWidth}
          />
        </Box>
      )}
    </Box>
  );
};

export default React.memo(TileBox);

const styles = {
  tileBox: {
    position: "relative",
    backgroundImage: "url()",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  emptyTileBox: {
    position: "relative",
    backgroundImage: "url()",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  tileBoxImageWrapper: {
    overflow: "hidden",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "rgba(217, 217, 217, 0.10)",
    cursor: "pointer",
    transition: "transform 0.3s ease, box-shadow 0.3s ease, z-index 0.3s ease",
    backdropFilter: "blur(2px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.3)",
      border: "1px solid var(--gray-900, #27292C) !important",
    },
  },
  tileIcon: {
    width: "24px",
    height: "24px",
    color: "gray.500",
  },
  tileBoxImage: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  tileBoxTag: {
    position: "absolute",
    width: "auto",
    padding: "4px 8px",
    color: "white",
    userSelect: "none",
    fontSize: "13px",
    lineHeight: "16.51px",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    borderRadius: "4px",
  },
};
