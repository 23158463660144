/**
 * Moodboard SDK: Provides utilities to interact with the Moodboard functionality via events.
 *
 * @module Custom Moodboard SDK
 */

import {
  Project,
  Placement,
  Config,
  Template,
  Product,
  Callback,
  MoodboardSDK,
} from "../state/types";

/**
 * Props for initializing MoodboardSDK.
 */
interface MoodboardSDKProps {
  onOpen: () => void;
  onClose: () => void;
  imageData: Placement[];
  projects: Project[];
  activeProjectIndex: number;
  trayStatus: string;
  template: Template;
  projectName: string;
}

declare global {
  interface Window {
    ByondXR?: {
      SDK?: {
        Moodboard?: MoodboardSDK;
      };
    };
  }
}

/**
 * Event names for Moodboard initialization and interaction.
 */
export const EVENTS = {
  INIT: "moodboard.init",
  DRAG_START: "moodboard.dragStart",
  SET_TILE: "moodboard.setTile",
  SET_PROJECTS: "moodboard.setProjects",
  SET_TEMPLATE: "moodboard.setTemplate",
  RESET_TRAY:"moodboard.resetTray"
};

/**
 * Event names for Moodboard event emitter functionalities.
 */
const MOODBOARD_EVENTS = {
  READY: "moodboard.readySDK",
  OPEN: "moodboard.opened",
  CLOSE: "moodboard.closed",
  RESET: "moodboard.reset",
  PRODUCT_ADDED: "moodboard.productAdded",
  PRODUCT_REMOVED: "moodboard.productRemoved",
  PROJECT_SAVED: "moodboard.projectSaved",
  PROJECT_SELECTED: "moodboard.projectSelected",
  NEW_PROJECT: "moodboard.newProjectClicked",
  VISUALIZER_LAUNCHED: "moodboard.visualizerLaunched",
  ORDER_SAMPLE: "moodboard.orderSampleClicked",
  APPLICATION_SELECTED: "moodboard.applicationSelected",
  APPLICATION_CHANGED: "moodboard.applicationChanged",
};

/**
 * Dispatches a custom event to the window with the provided event name and data.
 *
 * @param eventName - The name of the event to emit.
 * @param data - Optional data to include with the event.
 * @param callback - Optional callback for event response.
 */
const emitMoodboardEvent = (
  eventName: string,
  data: Record<string, any> = {},
  callback: Callback = console.log
): void => {
  const event = new CustomEvent("staticEvent", {
    detail: { data: { eventName, ...data }, callback },
  });
  window.dispatchEvent(event);
};

/**
 * Dispatches a custom event for Moodboard actions using the Moodboard event emitter.
 *
 * @param eventName - The name of the event to emit.
 * @param data - Optional data to include with the event.
 * @param callback - Optional callback for event response.
 */
const emitMoodboardEventEmitter = (
  eventName: string,
  data: Record<string, any> = {},
  callback: Callback = console.log
): void => {
  const event = new CustomEvent(eventName, {
    detail: { data: { eventName, ...data }, callback },
  });
  window.dispatchEvent(event);
};

// Moodboard event functions
export const init = (config: Config, callback: Callback = () => {}): void =>
  emitMoodboardEvent(EVENTS.INIT, { config }, callback);

export const dragStart = (
  product: Product,
  callback: Callback = () => {}
): void => emitMoodboardEvent(EVENTS.DRAG_START, { product }, callback);

export const setTile = (
  product: Product,
  callback: Callback = () => {}
): void => emitMoodboardEvent(EVENTS.SET_TILE, { product }, callback);

export const setProjects = (
  projects: Project[],
  activeProjectIndex?: number | null,
  callback: Callback = () => {}
): void =>
  emitMoodboardEvent(
    EVENTS.SET_PROJECTS,
    { projects, activeProjectIndex },
    callback
  );

export const setTemplate = (
  template: Template,
  callback: Callback = () => {}
): void => emitMoodboardEvent(EVENTS.SET_TEMPLATE, { template }, callback);

export const reset = (
  callback: Callback = () => {}
): void => emitMoodboardEvent(EVENTS.RESET_TRAY, {}, callback);

// Moodboard event emitter functions

export const emitReset = (callback: Callback = () => {}): void => {
  emitMoodboardEventEmitter(MOODBOARD_EVENTS.RESET, {}, callback);
};

export const emitSDKReady = (callback: Callback = () => {}): void => {
  emitMoodboardEventEmitter(MOODBOARD_EVENTS.READY, {}, callback);
};

export const emitTrayOpened = (callback: Callback = () => {}): void => {
  emitMoodboardEventEmitter(MOODBOARD_EVENTS.OPEN, {}, callback);
};

export const emitTrayClosed = (callback: Callback = () => {}): void => {
  emitMoodboardEventEmitter(MOODBOARD_EVENTS.CLOSE, {}, callback);
};

export const emitProductAdded = (
  productSku: string,
  callback: Callback = () => {}
): void => {
  emitMoodboardEventEmitter(
    MOODBOARD_EVENTS.PRODUCT_ADDED,
    { productSku },
    callback
  );
};

export const emitProductRemoved = (
  productSku: string,
  callback: Callback = () => {}
): void => {
  emitMoodboardEventEmitter(
    MOODBOARD_EVENTS.PRODUCT_REMOVED,
    { productSku },
    callback
  );
};

export const emitProjectSaved = (
  project: Project,
  callback: Callback = () => {}
): void => {
  emitMoodboardEventEmitter(
    MOODBOARD_EVENTS.PROJECT_SAVED,
    { project },
    callback
  );
};

export const emitProjectSelected = (
  projectId: string,
  projectName: string,
  callback: Callback = () => {}
): void => {
  emitMoodboardEventEmitter(
    MOODBOARD_EVENTS.PROJECT_SELECTED,
    { projectId, projectName },
    callback
  );
};

export const emitNewProject = (callback: Callback = () => {}): void => {
  emitMoodboardEventEmitter(MOODBOARD_EVENTS.NEW_PROJECT, {}, callback);
};

export const emitVisualizerLaunched = (
  trayData: Placement[],
  callback: Callback = () => {}
): void => {
  emitMoodboardEventEmitter(
    MOODBOARD_EVENTS.VISUALIZER_LAUNCHED,
    { trayData },
    callback
  );
};

export const emitOrderSample = (
  trayData: Placement[],
  callback: Callback = () => {}
): void => {
  emitMoodboardEventEmitter(
    MOODBOARD_EVENTS.ORDER_SAMPLE,
    { trayData },
    callback
  );
};

export const emitApplicationSelected = (
  selectedApplication: string,
  callback: Callback = () => {}
): void => {
  emitMoodboardEventEmitter(
    MOODBOARD_EVENTS.APPLICATION_SELECTED,
    { selectedApplication },
    callback
  );
};

export const emitApplicationChanged = (
  oldApplication: string,
  newApplication: string,
  callback: Callback = () => {}
): void => {
  emitMoodboardEventEmitter(
    MOODBOARD_EVENTS.APPLICATION_CHANGED,
    { oldApplication, newApplication },
    callback
  );
};

const getCurrentProjectData = (
  activeProject: Project,
  projectName: string,
  imageData: Placement[],
  template: Template
) => {

  const activeProjectId = activeProject?.id;
  const activeProjectName = activeProject?.name || projectName;
  const activeProjectTemplate = activeProject?.template || template;

  const data = imageData?.map((ele) => {
    return ele?.product
      ? {
          ...ele,
          product: { skuId: ele?.product?.skuId },
        }
      : ele;
  });

  return {
    id: activeProjectId || "",
    name: activeProjectName,
    data: data,
    template: activeProjectTemplate,
    project_type:"moodboard",
  };
};

/**
 * Initializes Moodboard SDK and attaches functions to the window.ByondXR.SDK.moodboard object.
 *
 * @param MoodboardSDKProps - Initialization properties for MoodboardSDK.
 */
export const setMoodboardSDK = ({
  onOpen,
  onClose,
  imageData,
  projects,
  activeProjectIndex,
  trayStatus,
  template,
  projectName,
}: MoodboardSDKProps): void => {
  const activeProject = projects[activeProjectIndex];
  
  if (typeof window !== "undefined") {
    window.ByondXR = {
      ...(window.ByondXR ?? {}),
      SDK: {
        ...(window.ByondXR?.SDK ?? {}),
        Moodboard: {
          open: (): void => {
            onOpen();
          },
          close: (): void => {
            onClose();
          },
          getCurrentProject: (): Project =>
            getCurrentProjectData(activeProject, projectName, imageData, template),
          getProjects: (): Project[] => projects,
          getCurrentProjectStatus: (): string => trayStatus,
          init,
          dragStart,
          setTile,
          setProjects,
          setTemplate,
          reset
        },
      },
    };
  }
};

/**
 * Sets global Moodboard event listeners and initializes the SDK in the window.ByondXR.SDK.moodboard object.
 */
let isInitialized = false;

export const setMoodboardEvents = () => {
  if (isInitialized) {
    return;
  }
  if (typeof window !== "undefined" && window.ByondXR?.SDK?.Moodboard) {
    const latestMoodboardSDK: MoodboardSDK = {
      ...window.ByondXR.SDK.Moodboard,
      init,
      dragStart,
      setTile,
      setProjects,
      setTemplate,
      reset
    };
    window.ByondXR.SDK.Moodboard = latestMoodboardSDK;
  } else {
    window.ByondXR = {
      ...(window.ByondXR ?? {}),
      SDK: {
        ...(window.ByondXR?.SDK ?? {}),
        Moodboard: {
          init,
          dragStart,
          setTile,
          setProjects,
          setTemplate,
          reset
        },
      },
    };
  }
  isInitialized = true;
  emitSDKReady();
};
