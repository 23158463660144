import { useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";
import projectConfig from "./configuration/projectConfig.json";
import tileImagesConfig from "./configuration/tileImagesConfig.json";
import designConfig from "./configuration/designConfig.json";
// import packageJson from "../package.json";

import { Project, Config, Product } from "./state/types";
import { MoodboardSDK } from "./type-doc/Types";

declare global {
  interface Window {
    ByondXR?: {
      SDK?: {
        Moodboard?: MoodboardSDK;
      };
    };
  }
}

export interface TemplateValue {
  name: string;
  key: string;
}

/**
 * Predefined tile images configuration.
 * @type {Product[]}
 */
const tileImagesData: Product[] = tileImagesConfig;

/**
 * Predefined design configuration.
 * @type {Config}
 */
const config: Config = designConfig;

/**
 * Predefined templates configuration.
 * @type {Template[]}
 */
const emptyTemplate: TemplateValue[] = [
  { name: "", key: "empty_template" },
];

const templates: TemplateValue[] = [
  { name: "Bathroom Template", key: "bathroom_template" },
  { name: "Kitchen Template", key: "kitchen_template" },
  { name: "Commercial Space Template", key: "commercial_space_template" },
  { name: "Living Room Template", key: "living_room_template" },
  { name: "Bedroom Template", key: "bedroom_template" },
];

const newTemplates: TemplateValue[] = [
  { name: "Template 1", key: "template_1" },
  { name: "Template 2", key: "template_2" },
  { name: "Template 3", key: "template_3" },
  { name: "Template 4", key: "template_4" },
  { name: "Template 5", key: "template_5" },
  { name: "Template 6", key: "template_6" },
  { name: "Template 7", key: "template_7" },
  { name: "Template 8", key: "template_8" }
];

/**
 * Predefined projects configuration.
 * @type {Project[]}
 */
const projects: Project | any = projectConfig;

/**
 * Custom hook for managing moodboard events and actions.
 * @returns {Object} An object containing event data and functions for handling events.
 */
const useBoardEventListener = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const config: Config = {
    height: "90vh",
    width:"345px",
    right: 0,
    bottom: 0,
    left: "auto",
    top: "auto",
    emptyTemplate: 3,
    template: "empty_template",
    debugMode: true,
    fontFamily: "Arial",
    classes:{
      header:"mb-header mb-header-new"
    },

    // required properties
    configPath: `./`,
    params: {
      catalogId: "674d890c51e116a06c68f4dc",
      organizationId: "5f0d72cd1616340007267996"
    },
  }

  useEffect(() => {
    window.addEventListener("moodboard.readySDK", (event: Event) => {
      const customEvent = event as CustomEvent;
      const { callback, data } = customEvent.detail;

      window?.ByondXR?.SDK?.Moodboard?.init(config);
      // window?.ByondXR?.SDK?.Moodboard?.open?.();
      // setTimeout(()=>{
      //   window?.ByondXR?.SDK?.Moodboard?.setProjects(projects, 0)
      // },100)

      // Optionally invoke the callback if defined
      if (callback && typeof callback === "function") {
        callback("Moodboard SDK is ready.");
      }
    });

    // Listen for the "moodboard.opened" event when the Moodboard UI is opened
    window.addEventListener("moodboard.opened", (event: Event) => {
      const customEvent = event as CustomEvent;
      const { callback, data } = customEvent.detail;
      // console.log("Moodboard opened.");

      if (callback && typeof callback === "function") {
        callback("Moodboard opened successfully!");
      }
    });

    // Listen for the "moodboard.closed" event when the Moodboard UI is closed
    window.addEventListener("moodboard.closed", (event: Event) => {
      const customEvent = event as CustomEvent;
      const { callback, data } = customEvent.detail;
      // console.log("Moodboard reset.");
      const userConfirmed = window.confirm(
        "Do you want to close tray without saving existing project?"
      );

      if (userConfirmed) {
        // Logic for confirm the prompt
        window?.ByondXR?.SDK?.Moodboard?.reset &&
        window?.ByondXR?.SDK?.Moodboard?.reset();
  
        window?.ByondXR?.SDK?.Moodboard?.close &&
        window?.ByondXR?.SDK?.Moodboard?.close();
        if (callback && typeof callback === "function") {
          callback("Moodboard closed successfully!");
        }
      } else {
        // Logic for dismissing the prompt
        // console.log("User dismissed the prompt.");
      }
    });

    // Listen for the "moodboard.reset" event for reset the Moodboard data
    window.addEventListener("moodboard.reset", (event: Event) => {
      const customEvent = event as CustomEvent;
      const { callback, data } = customEvent.detail;
      // console.log("Moodboard reset.");
      const userConfirmed = window.confirm(
        "Do you want to reset data without saving existing project?"
      );

      if (userConfirmed) {
        // Logic for confirm the prompt
        window?.ByondXR?.SDK?.Moodboard?.reset &&
        window?.ByondXR?.SDK?.Moodboard?.reset();
        if (callback && typeof callback === "function") {
          callback("Moodboard Reset successfully!");
        }
      } else {
        // Logic for dismissing the prompt
        // console.log("User dismissed the prompt.");
      }
    });

    // Listen for the "moodboard.productAdded" event when a product is added to the Moodboard
    window.addEventListener("moodboard.productAdded", (event: Event) => {
      const customEvent = event as CustomEvent;
      const { callback, data } = customEvent.detail;
      // console.log(`Product added: ${data.productSku}`);

      if (callback && typeof callback === "function") {
        callback("Product added successfully!");
      }
    });

    // Listen for the "moodboard.productRemoved" event when a product is removed from the Moodboard
    window.addEventListener("moodboard.productRemoved", (event: Event) => {
      const customEvent = event as CustomEvent;
      const { callback, data } = customEvent.detail;
      // console.log(`Product removed: ${data.productSku}`);

      if (callback && typeof callback === "function") {
        callback("Product removed successfully!");
      }
    });

    // Listen for the "moodboard.projectSaved" event when a project is saved
    window.addEventListener("moodboard.projectSaved", (event: Event) => {
      const customEvent = event as CustomEvent;
      const { callback, data } = customEvent.detail;
      // console.log("Project saved :", data.project);

      const isModified = window?.ByondXR?.SDK?.Moodboard?.getCurrentProjectStatus?.() !== 'pristine';

      if (isModified) {
        const userConfirmed = window.confirm(
          "Do you want to save project?"
        );

        if (userConfirmed) {
          if (callback && typeof callback === "function") {
            callback("Moodboard Save successfully!");
          }
        } else {
          // Logic for dismissing the prompt
          // console.log("User dismissed the prompt.");
        }
      } else {
        if (callback && typeof callback === "function") {
          callback("Moodboard Save successfully!");
        }
      }
    });

    // Listen for the "moodboard.projectSelected" event when a project is selected
    window.addEventListener("moodboard.projectSelected", (event: Event) => {
      const customEvent = event as CustomEvent;
      const { callback, data } = customEvent.detail;
      // console.log(
      //   `Project selected: id = ${data.projectId}, name = ${data.projectName}`
      // );

      const isModified = window?.ByondXR?.SDK?.Moodboard?.getCurrentProjectStatus?.() !== 'pristine';

      if (isModified) {
        const userConfirmed = window.confirm(
          "Do you want to switch projects without saving?"
        );

        if (userConfirmed) {
          // Logic for confirm the prompt
          if (callback && typeof callback === "function") {
            callback("Switched to a another project without saving!");
          }
        } else {
          // Logic for dismissing the prompt
          // console.log("User dismissed the prompt.");
        }
      } else {
        if (callback && typeof callback === "function") {
          callback("Switched to a another project without saving!");
        }
      }
    });

    // Listen for the "moodboard.newProjectClicked" event when a new project is added
    window.addEventListener("moodboard.newProjectClicked", (event: Event) => {
      const customEvent = event as CustomEvent;
      const { callback, data } = customEvent.detail;
      // console.log(`New Project Clicked!`);

      const isModified = true;

      if (isModified) {
        const userConfirmed = window.confirm(
          "Do you want to create a new project without saving existing project?"
        );

        if (userConfirmed) {
          // Logic for confirm the prompt
          onOpen();
          if (callback && typeof callback === "function") {
            callback("Switched to a new project without saving!");
          }
        } else {
          // Logic for dismissing the prompt
          // console.log("User dismissed the prompt.");
        }
      } else {
        if (callback && typeof callback === "function") {
          callback("Switched to a new project without saving!");
        }
      }
    });

    // Listen for the "moodboard.visualizerLaunched" event when the visualizer is launched
    window.addEventListener("moodboard.visualizerLaunched", (event: Event) => {
      const customEvent = event as CustomEvent;
      const { callback, data } = customEvent.detail;
      // console.log("Visualizer launched.", data.trayData);

      if (callback && typeof callback === "function") {
        callback("Visualizer launched successfully!");
      }
    });

    // Listen for the "moodboard.orderSampleClicked" event when an order sample action is triggered
    window.addEventListener("moodboard.orderSampleClicked", (event: Event) => {
      const customEvent = event as CustomEvent;
      const { callback, data } = customEvent.detail;
      // console.log("Order Sample triggered:", data.trayData);

      if (callback && typeof callback === "function") {
        callback("Order Sample triggered!");
      }
    });

    // Listen for the "moodboard.applicationSelected" event when an application is selected
    window.addEventListener("moodboard.applicationSelected", (event: Event) => {
      const customEvent = event as CustomEvent;
      const { callback, data } = customEvent.detail;
      // console.log(`Application selected: ${data.selectedApplication}`);

      if (callback && typeof callback === "function") {
        callback("Moodboard event callback invoked successfully!");
      }
    });

    // Listen for the "moodboard.applicationChanged" event when the application is changed
    window.addEventListener("moodboard.applicationChanged", (event: Event) => {
      const customEvent = event as CustomEvent;
      const { callback, data } = customEvent.detail;
      // console.log(
      //   `Application changed: ${data.oldApplication} to ${data.newApplication}`
      // );

      if (callback && typeof callback === "function") {
        callback("Application changed successfully!");
      }
    });
  }, []);

  return {
    templates,
    newTemplates,
    emptyTemplate,
    projects, // The predefined projects configuration
    tileImagesData, // The available product data for moodboard
    isTemplateDialogOpen: isOpen, // State for whether the template modal is open
    config, // Design configuration for moodboard customization
    onTemplateDialogOpen: onOpen, // Function to open the template modal
    onTemplateDialogClose: onClose, // Function to close the template modal
  };
};

export default useBoardEventListener;
