import React from "react";
import ReactDOM from "react-dom/client";
import TemplateDialog from "./TemplateDialog";
import { ChakraProvider } from "@chakra-ui/react";
import useBoardEventListener, { TemplateValue } from "./useBoardEventListener";
import { Project, Product, MoodboardSDK } from "./state/types";

// Define type for ProjectRenderer props

declare global {
  interface Window {
    ByondXR?: {
      SDK?: {
        Moodboard?: MoodboardSDK;
      };
    };
  }
}

interface ProjectRendererProps {
  projects: Project[];
}

const ProjectRenderer = ({ projects }: ProjectRendererProps) => {

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        alignItems: "stretch",
        width: "50%",
        flexWrap: "wrap",
        gap: 15,
        paddingBottom: "20px",
        boxShadow: "",
      }}
    >
      {projects &&
        projects?.map((project: Project, index: number) => (
          <button
            key={project.name}
            onClick={() => {
              const isModified = window?.ByondXR?.SDK?.Moodboard?.getCurrentProjectStatus?.() !== 'pristine';
              if (isModified) {
                const userConfirmed = window.confirm(
                  "Do you want to set projects without saving current progress?"
                );

                if (userConfirmed) {
                  window?.ByondXR?.SDK?.Moodboard?.setProjects(projects, index)
                } else {
                  // Logic for dismissing the prompt
                  // console.log("User dismissed the prompt.");
                }
              } else {
                window?.ByondXR?.SDK?.Moodboard?.setProjects(projects, index)
              }
            }}
            style={{
              background: "grey",
              color: "white",
              border: "none",
              outline: "none",
              padding: "3px 10px",
              borderRadius: "5px",
              // height: 30,
              width: 140,
            }}
          >
            {project.name}
          </button>
        ))}
    </div>
  );
};

// Define type for TemplateRenderer props
interface TemplateRendererProps {
  templates: TemplateValue[];
}

const TemplateRenderer: React.FC<TemplateRendererProps> = ({ templates }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        alignItems: "stretch",
        width: "50%",
        flexWrap: "wrap",
        gap: 15,
        borderRadius: "5px",
        boxShadow: "",
      }}
    >
      {templates.map((template) => (
        <button
          key={template?.key}
          onClick={() => {
            window?.ByondXR?.SDK?.Moodboard?.setTemplate({
              key: template?.key,
            });
            const projects = window?.ByondXR?.SDK?.Moodboard?.getProjects?.();
            if (projects?.length) {
              window?.ByondXR?.SDK?.Moodboard?.setProjects([]);
            }
          }}
          style={{
            background: "grey",
            color: "white",
            border: "none",
            outline: "none",
            padding: "3px 10px",
            borderRadius: "5px",
            // height: 30,
            width: 140,
          }}
        >
          {template.name || "Empty Template"}
        </button>
      ))}
    </div>
  );
};

// Define type for DraggableImage props
interface DraggableImageProps {
  tileData: Product;
  onDragStart: (e: React.DragEvent<HTMLImageElement>, params: Product) => void;
  addToMoodboard: (tile: Product) => void;
}

const DraggableImage = ({
  onDragStart,
  tileData,
  addToMoodboard,
}: DraggableImageProps) => {
  return (
    <div style={{ position: "relative" }}>
      <button
        onClick={(e) => addToMoodboard(tileData)}
        style={{
          position: "absolute",
          top: 5,
          left: 0,
          right: 0,
          margin: "auto",
          height: 30,
          width: 30,
          background: "#ffffffbf",
          borderRadius: 3,
        }}
      >
        =
      </button>
      <img
        draggable={true}
        src={tileData.src}
        height={200}
        width={200}
        onDragStart={(e) => onDragStart(e, tileData)}
        style={{ width: 200, height: 200, objectFit: "cover" }}
        alt=""
      />
    </div>
  );
};

const DraggableApp = () => {
  // const isLogin = true;

  const {
    tileImagesData,
    templates,
    newTemplates,
    emptyTemplate,
    projects,
    isTemplateDialogOpen,
    // config,
    // onTemplateDialogOpen,
    onTemplateDialogClose,
  } = useBoardEventListener();

  const handleDragStart = (
    e: React.DragEvent<HTMLImageElement>,
    tile: Product
  ) => {
    window?.ByondXR?.SDK?.Moodboard?.dragStart(tile);
  };

  return (
    <div
      style={{
        maxWidth: "1180px",
        margin: "auto",
        padding: "0 15px",
      }}
    >
      <div
        style={{
          gap: 5,
          display: "flex",
          justifyContent: "center",
          padding: "15px 0",
        }}
      >
        <button
          onClick={() => {
            window?.ByondXR?.SDK?.Moodboard?.open &&
              window?.ByondXR?.SDK?.Moodboard?.open();
          }}
          style={{
            background: "grey",
            color: "white",
            border: "none",
            outline: "none",
            padding: "3px 10px",
            borderRadius: "5px",
            height: 30,
            width: 140,
          }}
        >
          Show
        </button>

        <button
          onClick={() => {
            window?.ByondXR?.SDK?.Moodboard?.close &&
              window?.ByondXR?.SDK?.Moodboard?.close();
          }}
          style={{
            background: "grey",
            color: "white",
            border: "none",
            outline: "none",
            padding: "3px 10px",
            borderRadius: "5px",
            height: 30,
            width: 140,
          }}
        >
          Hide
        </button>

        <button
          onClick={() => {
            window?.ByondXR?.SDK?.Moodboard?.reset &&
              window?.ByondXR?.SDK?.Moodboard?.reset();
          }}
          style={{
            background: "grey",
            color: "white",
            border: "none",
            outline: "none",
            padding: "3px 10px",
            borderRadius: "5px",
            height: 30,
            width: 140,
          }}
        >
          Reset
        </button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: 15,
        }}
      >
        {tileImagesData?.map((tile, index) => (
          <div key={index}>
            <DraggableImage
              onDragStart={(e) => handleDragStart(e, tile)}
              tileData={tile}
              addToMoodboard={(tile) =>
                window?.ByondXR?.SDK?.Moodboard?.setTile(tile)
              }
            />
          </div>
        ))}
      </div>

      <div
        style={{
          padding: 15,
          margin: 40,
          border: "1px solid grey",
          borderRadius: "5px",
          boxShadow: "",
        }}
      >
        <h2
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            padding: "10px 0",
          }}
        >
          Projects
        </h2>
        <ProjectRenderer projects={projects}></ProjectRenderer>
        <div
          style={{
            borderBottom: "2px solid lightgrey",
          }}
        ></div>
        <h2
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            padding: "10px 0",
          }}
        >
          Templates
        </h2>
        <div
          style={{
            paddingBottom: 15,
            marginBottom: 15,
            borderBottom: "1px solid lightgrey",
          }}
        >
          <TemplateRenderer templates={emptyTemplate} />
        </div>
        <div
          style={{
            paddingBottom: 15,
            marginBottom: 15,
            borderBottom: "1px solid lightgrey",
          }}
        >
          <TemplateRenderer templates={templates} />
        </div>
        <div
          style={{
            paddingBottom: 15,
            marginBottom: 15,
            borderBottom: "1px solid lightgrey",
          }}
        >
          <TemplateRenderer templates={newTemplates} />
        </div>
      </div>
      <TemplateDialog
        selectTemplate={window?.ByondXR?.SDK?.Moodboard?.setTemplate}
        templates={templates}
        isOpen={isTemplateDialogOpen}
        onClose={onTemplateDialogClose}
      />
    </div>
  );
};

export function runPlayGround() {
  const root = ReactDOM.createRoot(
    document.getElementById("draggable") as HTMLElement
  );
  root.render(
    <ChakraProvider resetScope={'#draggable'} disableGlobalStyle={true}>
      <DraggableApp />
    </ChakraProvider>
  );
}
